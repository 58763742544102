
//import './modules/configModule.js';
//import {codeModule} from './modules/codeModule.js';
import {fadeIn} from './modules/fadeInModule';





let readMoreEvent = function(evt) {
    console.log("readMoreEvent");

    let me = evt.currentTarget;
    me.classList.toggle("open");
    let myHidden = me.getElementsByClassName("js-readmorebutton");
    myHidden[0].classList.toggle("hidden");

    /* let rMoreElement = evt.currentTarget.parentElement;
    //let theTextElement = rMoreElement.getElementsByClassName("textspace");
    //console.log(evt.currentTarget.parentElement);
    //console.log(theTextElement[0])

    rMoreElement.classList.toggle("open"); */

}


let rMore = document.getElementsByClassName('js-showreadmore');

for (var i = 0; i < rMore.length; i++) {
    rMore[i].addEventListener('click', readMoreEvent, false);
}


let menu = document.getElementById("menu");

const openMenu = function(e) {
    menu.classList.add("open");
}
const closeMenu = function(e) {
    menu.classList.remove("open");
}


let burgerButton = document.getElementById("burger");
burgerButton.addEventListener('click', openMenu, false);

let menuLinks = document.getElementsByClassName('js-menuClick');

for (var i = 0; i < menuLinks.length; i++) {
    menuLinks[i].addEventListener('click', closeMenu, false);
}



window.addEventListener('load', function () {
    fadeIn.init();
  }, false);

