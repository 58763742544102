
export const fadeIn = (() => {

    const init = function() {
        
        //console.log('fadeIn modeule fired');

        let options = {
            root: null,
            rootMargin: '-20% 0% -20% 0%',
            threshold: [0],
        };

        let triggerHeads = function() {
          console.log("triggerheads");
          let faders = document.getElementsByClassName('js-fadeHeads');
          console.log(faders)
          for (var f = 0; f < faders.length; f++) {
              console.log("bloop");
              faders[f].classList.add('shown'); 
          }
        }

        let fadeMeIn = function(entries, observer) {
            entries.forEach((entry) => {

              //console.log(entry.intersectionRatio);
              if (entry.intersectionRatio > 0) {
                //console.log("fade me in");
                entry.target.classList.add('fadein'); 
                fadeObserver.unobserve(entry.target);

                if (entry.target.classList.contains('js-showHeads')) {
                  triggerHeads();
                }

              }
            });
          };


        let fadeObserver = new IntersectionObserver(fadeMeIn, options);
        

        let faders = document.getElementsByClassName('js-fadein');
        for (var f = 0; f < faders.length; f++) {
            //faders[i].addEventListener('click', closeMenu, false);
            fadeObserver.observe(faders[f]);
            //console.log("found one");
        }


    }

    //console.log("fadeIn loaded...");

    return {
      init: init
    };

})();
